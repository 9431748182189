import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'productUnit/'
const getProductUnitPaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode,
        { headers: authHeader() },
    )
}
const createProductUnit = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateProductUnit = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteProductUnit = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getProductUnitById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}
const getProductUnitSelect = async () => {
    return await axios.get(API_URL + 'GetSelect', { headers: authHeader() })
}
export {
    getProductUnitPaging,
    createProductUnit,
    updateProductUnit,
    deleteProductUnit,
    getProductUnitById,
    getProductUnitSelect
}