import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'users/'

const login = async (param) => {
  return await axios.post(API_URL + 'GetAuth', param)
}
const logout = async (param) => {
  return await axios.post(API_URL + 'Logout', param, { headers: authHeader() })
}
const getUserById = async (id) => {
  return await axios.get(API_URL + 'GetById?userId=' + id, { headers: authHeader() })
}
const createUser = async (param) => {
  return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateUser = async (param) => {
  return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteUser = async (param) => {
  return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getAuthById = async () => {
  return await axios.get(API_URL + 'GetAuthById', { headers: authHeader() })
}
const getUserPaging = async (param) => {
  return await axios.get(
    API_URL +
    'GetPaging?currentPage=' +
    param?.currentPage +
    '&perPage=' +
    param?.perPage +
    '&sortColumn=' +
    param?.sortColumn +
    '&sortDirection=' +
    param?.sortDirection +
    '&search=' +
    param?.search +
    '&menuCode=' +
    param?.menuCode,
    { headers: authHeader() },
  )
}
const getUserSelect = async (id) => {
  return await axios.get(API_URL + 'GetSelect', { headers: authHeader() })
}
const getPrefixSelect = async (id) => {
  return await axios.get(API_URL + 'GetPrefixSelect', { headers: authHeader() })
}
export {
  login,
  getUserById,
  createUser,
  updateUser,
  deleteUser,
  logout,
  getAuthById,
  getUserPaging,
  getUserSelect,
  getPrefixSelect
}
