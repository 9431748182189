import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Space,
  Button,
} from "antd";
import Swal from 'sweetalert2'
import { createCompany, getCompany, updateCompany } from "../../services/company.service";
import { handleKeyDecimalPress, handleKeyPress } from "../../services/helper.service";
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};
const CompanyManage = () => {
  const [form] = Form.useForm();
  const [companyId, setCompanyId] = useState();

  const onFinish = async (data) => {
    let timerInterval;
    var param = {
      companyId: companyId ?? "",
      companyCode: data.companyCode,
      companyName: data.companyName,
      detail: data.detail,
      address: data.address,
      taxNo: data.taxNo,
      tel: data.tel,
      longitude: data.longitude,
      latitude: data.latitude,
    }
    if (companyId) {
      const { data: updateData } = await updateCompany(param);
      if (updateData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            window.location.reload()
          }
        })
      } else {
        Swal.fire(updateData?.message, '', 'error')
      }
    } else {
      const { data: insertData } = await createCompany(param);
      if (insertData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setCompanyId(insertData?.id)
            window.location.reload()
          }
        })
      } else {
        Swal.fire(insertData?.message, '', 'error')
      }
    }
  };


  const loadData = async () => {
    const { data } = await getCompany()
    if (data) {
      setCompanyId(data.companyId)
      form.setFieldsValue({
        companyId: data.companyId,
        companyCode: data.companyCode,
        companyName: data.companyName,
        address: data.address,
        taxNo: data.taxNo,
        tel: data.tel,
        longitude: data.longitude,
        latitude: data.latitude,
        detail: data.detail
      });
    }
  }
  useEffect(() => {
    loadData()
  }, []);
  return (
    <>
      <Card title="จัดการข้อมูลบริษัท" bordered={false}>
        <Form form={form} {...formItemLayout} onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`รหัสบริษัท`}
                name="companyCode"
                rules={[{ required: true, message: "กรุณากรอกรหัสบริษัท" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชื่อบริษัท`}
                name="companyName"
                rules={[{ required: true, message: "กรุณากรอกชื่อบริษัท" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label={`รายละเอียด`} name="detail">
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`ที่อยู่`} name="address">
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`เลขประจำตัวผู้เสียภาษี`}
                name="taxNo"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกเลขประจำตัวผู้เสียภาษี",
                  },
                ]}
              >
                <Input onKeyPress={handleKeyPress} maxLength={20} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`โทรศัพท์`} name="tel">
                <Input onKeyPress={handleKeyPress} maxLength={10} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item label={`ละติจูด`} name="latitude">
                <Input onKeyPress={handleKeyDecimalPress} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Item label={`ลองติจูด`} name="longitude">
                <Input onKeyPress={handleKeyDecimalPress} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="mt-3">
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <Space size="middle">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#125a05" }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
export default CompanyManage;
