import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Col,
    Row,
    Card,
    Form,
    Input,
    Switch,
    Space,
    Button,
    DatePicker,
    Select,
    Table,
    InputNumber
} from "antd";
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
import {
    DeleteOutlined,
    PlusCircleOutlined
} from "@ant-design/icons";
import { handleKeyDecimalPress, handleKeyPress } from "../../../services/helper.service";
import { getWarehouseSelect } from "../../../services/warehouse.service";
import { getSupplierSelect } from "../../../services/supplier.service";
import { getUserSelect } from "../../../services/user.service";
import { getProductSelect } from "../../../services/product.service";
import { createReceive, getReceiveById, updateReceive } from "../../../services/receive.service";
const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 6 },
        lg: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
        md: { span: 14 },
        lg: { span: 14 },
    },
};
const ReceiveManage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [detail, setDetail] = useState([])
    const [warehouseList, setWarehouseList] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [userList, setUserList] = useState([])
    const [productList, setProductList] = useState([])
    const addHandle = () => {
        const newDataObject = {
            receiveDetailId: uuidv4(),
            productId: null,
            serialNumber: null,
            lotNo: null,
            warehouseId: null,
            line: null,
            rack: null,
            loc: null,
            total: null,
            costPrice: null,
            totalPrice: null,
        };

        setDetail([...detail, newDataObject]);
    }
    const deleteRowById = (idToDelete) => {
        // Filter out the row with the specified ID
        const updatedData = detail?.filter(item => item.receiveDetailId !== idToDelete.receiveDetailId);
        setDetail(updatedData);
    };
    const loadDataDropDown = async () => {
        const { data: warehouse } = await getWarehouseSelect()
        setWarehouseList(warehouse)

        const { data: supplier } = await getSupplierSelect()
        setSupplierList(supplier)

        const { data: user } = await getUserSelect()
        setUserList(user)

        const { data: product } = await getProductSelect()
        setProductList(product)
    }
    const handleDetailChange = (value, fieldName, id) => {
        let d = detail.find((value) => value.receiveDetailId === id)
        if (fieldName === 'productId') {
            d.productId = value
        }
        else if (fieldName === 'serialNumber') {
            d.serialNumber = value
        }
        else if (fieldName === 'lotNo') {
            d.lotNo = value
        }
        else if (fieldName === 'warehouseId') {
            d.warehouseId = value
        } else if (fieldName === 'line') {
            d.line = value
        }
        else if (fieldName === 'rack') {
            d.rack = value
        } else if (fieldName === 'loc') {
            d.loc = value
        } else if (fieldName === 'total') {
            d.total = value ?? 0
            d.totalPrice = d.total * d.costPrice
        } else if (fieldName === 'costPrice') {
            d.costPrice = value ?? 0
            d.totalPrice = (d.total * d.costPrice).toFixed(2)
        }
        setDetail([...detail])
    };
    const columns = [
        {
            title: "#",
            width: 150,
            align: "center",
            render: (row) => (
                <div>
                    <Button
                        key={row.receiveDetailId}
                        type="text"
                        icon={<DeleteOutlined />}
                        style={{
                            fontSize: "16px",
                            width: 50,
                            height: 50,
                            color: "red",
                        }}
                        onClick={() => {
                            deleteRowById(row)
                        }}
                    />
                </div>
            ),
        },
        {
            title: "รายละเอียดสินค้า",
            dataIndex: "productId",
            key: "productId",
            sorter: false,
            width: 300,
            render: (text, record) => (
                <Form.Item
                    name={`productId_${record.receiveDetailId}`}
                    rules={[{ required: true, message: 'กรุณาเลือกสินค้า' }]}
                    style={{ marginBottom: 0 }}
                >
                    <Select
                        placeholder="กรุณาเลือก"
                        key={'productId_' + record.receiveDetailId}
                        style={{ width: 270 }}
                        onChange={(e) => handleDetailChange(e, 'productId', record.receiveDetailId)}
                        options={productList}
                        value={record?.productId}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        optionFilterProp="children"
                        showSearch
                    />
                </Form.Item>
            )
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
            key: "serialNumber",
            sorter: false,
            width: 200,
            render: (text, record) => (
                <Input maxLength={50} key={'serialNumber_' + record.receiveDetailId} value={record?.serialNumber} onChange={(e) => { handleDetailChange(e.target.value, 'serialNumber', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "Lot No.",
            dataIndex: "lotNo",
            key: "lotNo",
            sorter: false,
            width: 200,
            render: (text, record) => (
                <Input maxLength={50} key={'lotNo_' + record.receiveDetailId} value={record?.lotNo} onChange={(e) => { handleDetailChange(e.target.value, 'lotNo', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "คลัง",
            dataIndex: "warehouseId",
            key: "warehouseId",
            sorter: false,
            width: 300,
            render: (text, record) => (
                <Form.Item
                    name={`warehouseId_${record.receiveDetailId}`}
                    rules={[{ required: true, message: 'กรุณาเลือกคลังสินค้า' }]}
                    style={{ marginBottom: 0 }}
                >
                    <Select
                        placeholder="กรุณาเลือก"
                        key={'warehouseId_' + record.receiveDetailId}
                        style={{ width: 270 }}
                        onChange={(e) => handleDetailChange(e, 'warehouseId', record.receiveDetailId)}
                        options={warehouseList}
                        value={record?.warehouseId}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        optionFilterProp="children"
                        showSearch
                    />
                </Form.Item>
            )
        },
        {
            title: "แถว",
            dataIndex: "line",
            key: "line",
            sorter: false,
            width: 100,
            render: (text, record) => (
                <Input maxLength={20} key={'line_' + record.receiveDetailId} value={record?.line} onChange={(e) => { handleDetailChange(e.target.value, 'line', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "ชั้น",
            dataIndex: "rack",
            key: "rack",
            sorter: false,
            width: 100,
            render: (text, record) => (
                <Input maxLength={20} key={'rack_' + record.receiveDetailId} value={record?.rack} onChange={(e) => { handleDetailChange(e.target.value, 'rack', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "ล็อค",
            dataIndex: "loc",
            key: "loc",
            sorter: false,
            width: 100,
            render: (text, record) => (
                <Input maxLength={20} key={'loc_' + record.receiveDetailId} value={record?.loc} onChange={(e) => { handleDetailChange(e.target.value, 'loc', record.receiveDetailId) }} style={{ width: '90%' }} />
            )
        },
        {
            title: "จำนวน",
            dataIndex: "total",
            key: "total",
            sorter: false,
            width: 150,
            render: (text, record) => (
                <Form.Item
                    name={`total_${record.receiveDetailId}`}
                    rules={[{ required: true, message: 'กรุณากรอกจำนวน' }]}
                    style={{ marginBottom: 0 }}
                >
                    <InputNumber
                        min={0}
                        key={`total_${record.receiveDetailId}`}
                        value={record?.total}
                        onChange={(value) => handleDetailChange(value, 'total', record.receiveDetailId)}
                        style={{ width: '100%', textAlign: 'right' }}
                    />
                </Form.Item>
            )
        },
        {
            title: "ราคาต่อหน่วย",
            dataIndex: "costPrice",
            key: "costPrice",
            sorter: false,
            width: 200,
            render: (text, record) => (
                <Form.Item
                    name={`costPrice_${record.receiveDetailId}`}
                    rules={[{ required: true, message: 'กรุณากรอกราคาต่อหน่วย' }]}
                    style={{ marginBottom: 0 }}
                >
                    <Input
                        key={`costPrice_${record.receiveDetailId}`}
                        value={record?.costPrice}
                        onChange={(e) => handleDetailChange(e.target.value, 'costPrice', record.receiveDetailId)}
                        style={{ width: '100%', textAlign: 'right' }}
                        onKeyPress={handleKeyDecimalPress}
                    />
                </Form.Item>
            )
        },
        {
            title: "จำนวนเงินทั้งหมด",
            dataIndex: "totalPrice",
            key: "totalPrice",
            sorter: false,
            width: 200,
            render: (text, record) => (
                <Input key={'totalPrice_' + record.receiveDetailId} readOnly style={{ width: '90%', backgroundColor: '#125a05', color: '#ffffff', textAlign: 'right' }} value={record?.totalPrice} />
            )
        },
    ];
    const onFinish = async (data) => {
        if (detail.length === 0) {
            Swal.fire('กรุณาเพิ่มรายละเอียดการจัดรับสินค้าอย่างน้อย 1 รายการ', '', 'error')
        }
        else if (detail.find(x => x.productId === null)) {
            Swal.fire('กรุณาเลือกสินค้ารายละเอียด', '', 'error')
        } else if (detail.find(x => x.warehouseId === null)) {
            Swal.fire('กรุณาเลือกคลังในรายละเอียด', '', 'error')
        } else if (detail.find(x => x.total === null || x.total === '' || x.total === 0)) {
            Swal.fire('กรุณากรอกจำนวนในรายละเอียด', '', 'error')
        } else if (detail.find(x => x.costPrice === null || x.costPrice === '' || x.costPrice === 0)) {
            Swal.fire('กรุณากรอกราคาต่อหน่วยในรายละเอียด', '', 'error')
        }
        else {

            let timerInterval;
            var param = {
                receiveId: receiveId ?? "",
                receiveDate: data.receiveDate,
                supplierId: data.supplierId,
                receiveRef: data.receiveRef,
                receiverId: data.receiverId,
                remark: data.remark,
                details: detail,
            }
            if (location?.state?.receiveId) {
                const { data: updateData } = await updateReceive(param);
                if (updateData.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'บันทึกข้อมูล',
                        text: 'บันทึกสำเร็จ',
                        html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
                        timer: 500,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            window.location.reload()
                        }
                    })
                } else {
                    Swal.fire(updateData?.message, '', 'error')
                }
            } else {
                const { data: insertData } = await createReceive(param);
                if (insertData.isSuccess) {
                    Swal.fire({
                        icon: 'success',
                        title: 'บันทึกข้อมูล',
                        text: 'บันทึกสำเร็จ',
                        html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
                        timer: 500,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            setReceiveId(insertData?.id)
                            navigate("/receive/manage", { state: { receiveId: insertData?.id } });
                            window.location.reload()
                        }
                    })
                } else {
                    Swal.fire(insertData?.message, '', 'error')
                }
            }
        }
    };
    const [receiveId, setReceiveId] = useState();
    const [isActive, setIsActive] = useState(true);
    const loadData = async (param) => {
        const { data } = await getReceiveById(param)
        if (data) {
            setIsActive(data.isActive)
            form.setFieldsValue({
                receiveNo: data.receiveNo,
                receiveDate: data.receiveDate,
                supplierId: data.supplierId,
                receiveRef: data.receiveRef,
                receiverId: data.receiverId,
                remark: data.remark,
            });
            setDetail(data?.details)
        }
    }
    useEffect(() => {
        loadDataDropDown()
        if (location?.state?.receiveId) {
            setReceiveId(location?.state?.receiveId)
            loadData(location?.state?.receiveId)
        }
    }, []);
    return (
        <>
            <Card title="จัดการรับสินค้าเข้าคลัง" bordered={false}>
                <Form form={form} {...formItemLayout} onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={`เลขที่เอกสาร`}
                                name="receiveNo"
                            >
                                <Input readOnly className="read-only" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={`วันที่รับสินค้า`}
                                name="receiveDate"
                                rules={[{ required: true, message: "กรุณาเลือกวันที่รับสินค้า" }]}
                            >
                                <DatePicker showTime style={{ width: '100%' }} placeholder="yyyy-MM-dd HH:mm:ss" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`ผู้จัดซื้อ`} name="supplierId"
                                rules={[{ required: true, message: "กรุณาเลือกผู้จัดซื้อ" }]}
                            >
                                <Select
                                    placeholder="กรุณาเลือก"
                                    style={{ width: "100%" }}
                                    options={supplierList}
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    optionFilterProp="children"
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={`เลขที่เอกสารอ้างอิง`}
                                name="receiveRef"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณากรอกเลขที่เอกสารอ้างอิง",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`ผู้รับสินค้า`} name="receiverId"
                                rules={[{ required: true, message: "กรุณาเลือกผู้รับสินค้า" }]}
                            >
                                <Select
                                    placeholder="กรุณาเลือก"
                                    style={{ width: "100%" }}
                                    options={userList}
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    optionFilterProp="children"
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} lg={12}>
                            <Form.Item label={`หมายเหตุ`} name="remark">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col className="gutter-row" xs={24} lg={24}>
                            <Button
                                className="ant-btn-submit"
                                icon={<PlusCircleOutlined style={{ color: "#FFFFFF" }} />}
                                onClick={() =>
                                    addHandle()
                                }
                                size="large"
                                style={{ color: "#FFFFFF", border: "#555843" }}
                            >
                                เพิ่ม
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: '10px' }}>
                        <Col className="gutter-row" span={24}>
                            <Table columns={columns} scroll={{ x: 500 }} dataSource={detail} />
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: '10px' }}>
                        <Col span={24}>
                            <div style={{ textAlign: "center" }}>
                                <Space size="middle">
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        กลับ
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ backgroundColor: "#125a05" }}
                                    >
                                        ยืนยัน
                                    </Button>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card >
        </>
    );
};
export default ReceiveManage;
