import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Switch,
  Space,
  Button,
} from "antd";
import Swal from 'sweetalert2'
import { createProductUnit, getProductUnitById, updateProductUnit } from "../../services/unit.service";
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};
const ProductUnitManage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const onFinish = async (data) => {
    let timerInterval;
    var param = {
      unitId: unitId ?? "",
      unitCode: data.unitCode,
      unitName: data.unitName,
      detail: data.detail,
      isActive: isActive,
    }
    if (location?.state?.unitId) {
      const { data: updateData } = await updateProductUnit(param);
      if (updateData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            window.location.reload()
          }
        })
      } else {
        Swal.fire(updateData?.message, '', 'error')
      }
    } else {
      const { data: insertData } = await createProductUnit(param);
      if (insertData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setUnitId(insertData?.id)
            navigate("/unit/manage", { state: { unitId: insertData?.id } });
            window.location.reload()
          }
        })
      } else {
        Swal.fire(insertData?.message, '', 'error')
      }
    }
  };
  const [unitId, setUnitId] = useState();
  const [isActive, setIsActive] = useState(true);
  const loadData = async (param) => {
    const { data } = await getProductUnitById(param)
    if (data) {
      setIsActive(data.isActive)
      form.setFieldsValue({
        unitId: data.unitId,
        unitCode: data.unitCode,
        unitName: data.unitName,
        detail: data.detail,
      });
    }
  }
  useEffect(() => {
    if (location?.state?.unitId) {
      setUnitId(location?.state?.unitId)
      loadData(location?.state?.unitId)
    } else {
      setIsActive(true)
    }
  }, []);
  return (
    <>
      <Card title="จัดการข้อมูลหน่วยสินค้า" bordered={false}>
        <Form form={form} {...formItemLayout} onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`รหัสหน่วยสินค้า`}
                name="unitCode"
                rules={[
                  { required: true, message: "กรุณากรอกรหัสหน่วยสินค้า" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชื่อหน่วยสินค้า`}
                name="unitName"
                rules={[
                  { required: true, message: "กรุณากรอกชื่อหน่วยสินค้า" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`รายละเอียด`} name="detail">
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`ใช้งาน`} name="isActive">
                <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="mt-3">
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <Space size="middle">
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    กลับ
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#125a05" }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
export default ProductUnitManage;
