import Logo from "../components/Logo";
import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { login } from "../services/user.service";
import Swal from 'sweetalert2'
const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [deviceName, setDeviceName] = useState('')
  const [deviceIp, setDeviceIp] = useState('')
  const [browser, setBrowser] = useState('')
  const onFinish = async () => {
    const { data } = await login({
      username, password, deviceName, deviceIp, browser
    })
    if (data?.isSuccess) {
      let timerInterval;
      Swal.fire({
        title: "เข้าสู่ระบบ",
        html: "กำลังเข้าสู่ระบบภายใน <b></b> วินาที.",
        timer: 500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Swal.getTimerLeft()}`;
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          localStorage.setItem('token', data?.token)
          localStorage.setItem('user', JSON.stringify(data?.data))
          navigate("/");
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "เข้าสู่ระบบ",
        text: data?.message,
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#555843"
      });
    }
  };
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    const getDeviceName = () => {
      setDeviceName(navigator.userAgent)
    }
    getDeviceName()

    const getDeviceIp = async () => {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      setDeviceIp(data.ip)
    }
    getDeviceIp()

    const getBrowserInfo = () => {
      const ua = navigator.userAgent
      let tem
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident|edge(?=\/))\/?\s*(\d+)/i) || []
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return `IE ${tem[1] || ''}`
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
      setBrowser(M.join(' '))
    }
    getBrowserInfo()

  }, [])
  return (
    <div className="login-page">
      <div className="login-form-container text-center">
        {/* <Logo width={200} /> */}
        <h1 className="mt-4">Barry Karnkaset</h1>
        <h3 className="mt-4">เข้าสู่ระบบ</h3>
        <Form className="mt-4" name="login-form" onFinish={onFinish}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "กรุณากรอกผู้ใช้งาน" }]}
          >
            <Input placeholder="ผู้ใช้งาน" maxLength={10} onChange={(e) => setUsername(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "กรุณากรอกรหัสผ่าน" }]}
          >
            <Input.Password placeholder="รหัสผ่าน" maxLength={20} onChange={(e) => setPassword(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="ant-btn-submit"
              htmlType="submit"
              block
            >
              เข้าสู่ระบบ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Login;
