import React, { useEffect, useState } from "react";
import { Button, Modal, Layout } from "antd";
import Swal from 'sweetalert2'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getAuthById, logout } from "../services/user.service";
// import { logout } from "../services/user.service";

const { Header } = Layout;
const { confirm } = Modal;
const AppHeader = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const [deviceName, setDeviceName] = useState('')
  const [deviceIp, setDeviceIp] = useState('')
  const [browser, setBrowser] = useState('')
  const getAuthData = async () => {
    try {
      const response = await getAuthById()
      if (response.data) {
        localStorage.setItem('user', JSON.stringify(response?.data))
      }
    } catch (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate('/login')
    }
  }
  useEffect(() => {
    if (!token || !user) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href = '/login';
    } else {
      getAuthData()
    }
    const getDeviceName = () => {
      setDeviceName(navigator.userAgent)
    }
    getDeviceName()

    // const getDeviceIp = async () => {
    //   const response = await fetch('https://api.ipify.org?format=json')
    //   const data = await response.json()
    //   setDeviceIp(data.ip)
    // }
    // getDeviceIp()

    const getBrowserInfo = () => {
      const ua = navigator.userAgent
      let tem
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident|edge(?=\/))\/?\s*(\d+)/i) || []
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return `IE ${tem[1] || ''}`
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
      setBrowser(M.join(' '))
    }
    getBrowserInfo()

  }, []);
  const logoutFunction = async () => {
    const { data } = await logout({
      username: user?.username, token: token, deviceName, deviceIp, browser
    });

    if (data?.isSuccess) {
      let timerInterval;
      Swal.fire({
        title: "ออกจากระบบ",
        html: "กำลังออกจากระบบภายใน <b></b> วินาที.",
        timer: 500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Swal.getTimerLeft()}`;
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "ออกจากระบบ",
        text: data?.message,
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#555843"
      });
    }
  };
  const handleLogout = () => {
    Swal.fire({
      title: "ออกจากระบบ?",
      text: "กรุณากดปุ่มยืนยันเพื่อทำการออกจากระบบ",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      cancelButtonColor: "#ff4d4f",
      confirmButtonColor: "#555843",
      confirmButtonText: "ออกจากระบบ"
    }).then((result) => {
      if (result.isConfirmed) {
        logoutFunction();
        // localStorage.removeItem("token");
        // localStorage.removeItem("user");
        // navigate("/login");
      }
    });
  };
  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#125A05",
        padding: "0px",
        justifyContent: "space-between", // Add this line
      }}
    >
      <Button
        type="text"
        icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => props.setCollapsed(!props.collapsed)}
        style={{
          fontSize: "16px",
          width: 64,
          height: 64,
          color: "#FFFFFF",
        }}
      />
      <div style={{ color: "#FFFFFF", fontWeight: "bold" }}>
        <span style={{ fontSize: "0.7rem" }}>
          ชื่อผู้ใช้งาน : {user?.fullname}
        </span>
        <Button
          type="text"
          icon={<LogoutOutlined />}
          onClick={() => handleLogout()}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
            color: "#FFFFFF",
          }}
        />
      </div>
    </Header>
  );
};
export default React.memo(AppHeader);
