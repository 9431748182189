const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Check if the pressed key is a digit or allowed control keys (backspace, delete)
    const isValidInput = /^[0-9]$/.test(keyValue) || [8, 46].includes(keyCode);

    // Prevent input if the pressed key is not valid
    if (!isValidInput) {
        event.preventDefault();
    }
};

const handleKeyDecimalPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Check if the pressed key is a digit, a decimal point, or allowed control keys (backspace, delete)
    const isValidInput = /^[0-9.]$/.test(keyValue) || [8, 46].includes(keyCode);

    // Prevent input if the pressed key is not valid
    if (!isValidInput) {
        event.preventDefault();
    }

    // Check for more than one decimal point
    if (keyValue === '.' && event.target.value.includes('.')) {
        event.preventDefault();
    }
};
export {
    handleKeyPress,
    handleKeyDecimalPress
}