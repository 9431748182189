import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'company/'
const getCompany = async () => {
    return await axios.get(API_URL + 'Get', { headers: authHeader() })
}
const createCompany = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateCompany = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
export {
    getCompany,
    createCompany,
    updateCompany
}