import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Switch,
  Space,
  Button,
  Select,
} from "antd";
import { createUser, getPrefixSelect, getUserById, updateUser } from "../../services/user.service";
import { getRoleSelect } from "../../services/role.service";
import Swal from 'sweetalert2'
import { handleKeyPress } from "../../services/helper.service";
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};
const UserManage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [roleList, setRoleList] = useState();
  const [prefixList, setPrefixList] = useState();
  const [isActive, setIsActive] = useState();
  const [userId, setUserId] = useState();
  const onFinish = async (value) => {
    let timerInterval;
    var param = {
      userId: userId,
      username: value?.username,
      password: value?.password,
      prefixId: value?.prefixId,
      firstname: value?.firstname,
      surname: value?.surname,
      roleId: value?.roleId,
      tel: value?.tel,
      isActive: isActive
    }
    if (location?.state?.userId) {
      const { data: updateData } = await updateUser(param);
      if (updateData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            window.location.reload()
          }
        })
      } else {
        Swal.fire(updateData?.message, '', 'error')
      }
    } else {
      const { data: insertData } = await createUser(param);
      if (insertData.isSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูล',
          text: 'บันทึกสำเร็จ',
          html: 'กำลังโหลดข้อมูลใหม่ภายใน <b></b> วินาที.',
          timer: 500,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            setUserId(insertData?.id)
            navigate("/user/manage", { state: { userId: insertData?.id } });
            window.location.reload()
          }
        })
      } else {
        Swal.fire(insertData?.message, '', 'error')
      }
    }
  };

  const validateUsername = (rule, value, callback) => {
    const englishAndNumberRegex = /^[a-zA-Z0-9]+$/;
    if (!englishAndNumberRegex.test(value)) {
      callback("Username ต้องประกอบด้วยตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"); // Display message if username is not in English and numbers
    } else {
      callback(); // Validation passed
    }
  };
  const loadDdl = async () => {
    const { data: roleData } = await getRoleSelect()
    const { data: prefixData } = await getPrefixSelect()
    if (roleData) {
      setRoleList(roleData)
    }
    if (prefixData) {
      setPrefixList(prefixData)
    }
  }
  const loadData = async (param) => {
    const { data } = await getUserById(param)
    if (data) {
      setIsActive(data.isActive)
      form.setFieldsValue({
        userId: data.userId,
        username: data.username,
        prefixId: data.prefixId,
        firstname: data.firstname,
        surname: data.surname,
        tel: data.tel,
        roleId: data.roleId,
        isActive: data.isActive
      });
    }
  }
  useEffect(() => {
    loadDdl()
    if (location?.state?.userId) {
      setUserId(location?.state?.userId)
      loadData(location?.state?.userId)
      //edit
    } else {
      setIsActive(true)
    }
  }, []);
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const filterPrefixOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <>
      <Card title="จัดการข้อมูลผู้ใช้งาน" bordered={false}>
        <Form form={form} {...formItemLayout} onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`Username`}
                name="username"
                rules={[
                  { required: true, message: "กรุณากรอก username" },
                  { validator: validateUsername },
                  { min: 5, message: "Username ต้องมีอย่างน้อย 5 ตัวอักษร" },
                ]}
              >
                <Input disabled={userId ? true : false} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`Password`}
                name="password"
                rules={[
                  { required: userId ? false : true, message: "กรุณากรอก password" },
                  { min: 5, message: "Password ต้องมีอย่างน้อย 5 ตัวอักษร" },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`คำนำหน้า`}
                name="prefixId"
                rules={[
                  { required: true, message: "กรุณาเลือก คำนำหน้า" },
                ]}
              >
                <Select
                  allowClear
                  options={prefixList}
                  showSearch
                  placeholder="กรุณาเลือก"
                  filterOption={filterPrefixOption}
                  optionFilterProp="children"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ระดับผู้ใช้งาน`}
                name="roleId"
                rules={[
                  { required: true, message: "กรุณาเลือก ระดับผู้ใช้งาน" },
                ]}
              >
                <Select
                  allowClear
                  options={roleList}
                  showSearch
                  placeholder="กรุณาเลือก"
                  filterOption={filterOption}
                  optionFilterProp="children"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`ชื่อ`}
                name="firstname"
                rules={[
                  { required: true, message: "กรุณากรอกชื่อ" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={`โทรศัพท์`}
                name="tel"
              >
                <Input maxLength={10} placeholder="08xxxxxxxx"
                  onKeyDown={handleKeyPress} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={`สกุล`}
                name="surname"
                rules={[
                  { required: true, message: "กรุณากรอกสกุล" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label={`ใช้งาน`}>
                <Switch checked={isActive} onChange={(e) => setIsActive(e)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="mt-3">
            <Col span={24}>
              <div style={{ textAlign: "center" }}>
                <Space size="middle">
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    กลับ
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#125a05" }}
                  >
                    ยืนยัน
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
export default UserManage;
