import { Layout } from "antd";
const { Footer } = Layout;
const AppFooter = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        backgroundColor: "#125A05",
        color: "#FFFFFF",
        bottom: 0,
        width: "100%",
      }}
    >
      Copyright © 2023 BARRY-KARNKASET All rights reserved Powered By Sarun Yuanyong
    </Footer>
  );
};
export default AppFooter;
