import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'supplier/'
const getSupplierPaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode,
        { headers: authHeader() },
    )
}
const createSupplier = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateSupplier = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteSupplier = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getSupplierById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}
const getSupplierSelect = async (id) => {
    return await axios.get(API_URL + 'GetSelect', { headers: authHeader() })
}
export {
    getSupplierPaging,
    createSupplier,
    updateSupplier,
    deleteSupplier,
    getSupplierById,
    getSupplierSelect
}