import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_API_URL + 'product/'
const getProductPaging = async (param) => {
    return await axios.get(
        API_URL +
        'GetPaging?currentPage=' +
        param?.currentPage +
        '&perPage=' +
        param?.perPage +
        '&sortColumn=' +
        param?.sortColumn +
        '&sortDirection=' +
        param?.sortDirection +
        '&search=' +
        param?.search +
        '&menuCode=' +
        param?.menuCode,
        { headers: authHeader() },
    )
}
const createProduct = async (param) => {
    return await axios.post(API_URL + 'Create', param, { headers: authHeader() })
}
const updateProduct = async (param) => {
    return await axios.post(API_URL + 'Update', param, { headers: authHeader() })
}
const deleteProduct = async (param) => {
    return await axios.post(API_URL + 'Delete', param, { headers: authHeader() })
}
const getProductById = async (id) => {
    return await axios.get(API_URL + 'GetById?id=' + id, { headers: authHeader() })
}
const getProductSelect = async () => {
    return await axios.get(API_URL + 'GetSelect', { headers: authHeader() })
}
export {
    getProductPaging,
    createProduct,
    updateProduct,
    deleteProduct,
    getProductById,
    getProductSelect
}