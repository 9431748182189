import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import routes from "../routes";
import AppBreadcrumb from "./AppBreadcrumb";
const { Content } = Layout;

const AppContent = () => {
  return (
    <Content
      style={{
        margin: "24px 16px",
        padding: "0 20px",
      }}
    >
      <AppBreadcrumb />
      <div
        style={{
          padding: 24,
          backgroundColor: "#ffffff",
          height: "calc(100vh - 250px)",
          overflow: "auto",
        }}
      >
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route path="/" element={<Navigate to="/home" replace />} />
        </Routes>
      </div>
    </Content>
  );
};
export default React.memo(AppContent);
