import { Layout, Menu } from "antd";
import AppHeader from "../components/AppHeader";
import AppContent from "../components/AppContent";
import AppFooter from "../components/AppFooter";
import { useEffect, useState } from "react";
import {
  DashboardOutlined,
  FileTextOutlined,
  SettingOutlined,
  TeamOutlined,
  SolutionOutlined,
  DatabaseOutlined,
  ShoppingOutlined,
  GoldOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  FileExcelOutlined,
  HddOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import Logo from "../components/Logo";
import { useLocation, useNavigate } from "react-router-dom";
const { Sider } = Layout;
const DefaultLayout = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const currentLocation = useLocation().pathname;
  const currentLocationSplit = currentLocation.split("/");
  const [activeMenu, setActiveMenu] = useState(["/" + currentLocationSplit[1]]);
  const handleMenuClick = (event) => {
    setActiveMenu([event.key]);
    navigate(event?.keyPath[0]);
  };
  useEffect(() => {
    if (window.innerWidth < 800) setCollapsed(true);
  }, []);
  return (
    <Layout
      style={{
        height: "calc(100vh)",
      }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ backgroundColor: "#A7D397" }}
        collapsedWidth={0}
      >
        <div
          style={{
            padding: 24,
            backgroundColor: "#125A05",
            textAlign: "center",
            color: "#FFFFFF",
          }}
        >
          {collapsed ? "BARRY" : "BARRY-KARNKASET"}
          {/* <Logo width={70} className="text-center" /> */}
        </div>

        <Menu
          style={{ backgroundColor: "#A7D397", color: "#555843" }}
          mode="inline"
          defaultSelectedKeys={activeMenu}
          onClick={handleMenuClick}
          items={[
            {
              key: "/",
              icon: <DashboardOutlined />,
              label: "แดชบอร์ด",
              url: "/",
            },
            {
              key: "warehouse-management",
              icon: <DatabaseOutlined />,
              label: "จัดการคลังสินค้า",
              children: [
                {
                  key: "/receive",
                  icon: <FileAddOutlined />,
                  label: "รับสินค้าเข้าคลัง",
                  url: "/receive",
                },
              ],
            },
            // {
            //   key: "buyer",
            //   icon: <ShoppingCartOutlined />,
            //   label: "การสั่งซื้อ",
            //   children: [
            //     {
            //       key: "/pr",
            //       icon: <FileAddOutlined />,
            //       label: "ใบขอซื้อ",
            //       url: "/pr",
            //     },
            //     {
            //       key: "/po",
            //       icon: <FileTextOutlined />,
            //       label: "ใบสั่งซื้อ",
            //       url: "/po",
            //     },
            //     {
            //       key: "/gr",
            //       icon: <FileProtectOutlined />,
            //       label: "ใบรับสินค้าดี",
            //       url: "/gr",
            //     },
            //     {
            //       key: "/gi",
            //       icon: <FileExcelOutlined />,
            //       label: "ใบรับสินค้าเสีย",
            //       url: "/gi",
            //     },
            //   ],
            // },

            {
              key: "master",
              icon: <AppstoreOutlined />,
              label: "มาสเตอร์",
              children: [
                {
                  key: "/supplier",
                  icon: <ShopOutlined />,
                  label: "ผู้จัดซื้อ",
                  url: "/supplier",
                },
                {
                  key: "/customer",
                  icon: <SolutionOutlined />,
                  label: "ลูกค้า",
                  url: "/customer",
                },
                {
                  key: "/warehouse",
                  icon: <HddOutlined />,
                  label: "คลังสินค้า",
                  url: "/warehouse",
                },
                {
                  key: "/category",
                  icon: <GoldOutlined />,
                  label: "กลุ่มสินค้า",
                  url: "/category",
                },
                {
                  key: "/unit",
                  icon: <DeploymentUnitOutlined />,
                  label: "หน่วยสินค้า",
                  url: "/unit",
                },
                {
                  key: "/product",
                  icon: <ShoppingOutlined />,
                  label: "สินค้า",
                  url: "/product",
                },
              ],
            },
            {
              key: "setting",
              icon: <SettingOutlined />,
              label: "ตั้งค่า",
              children: [
                {
                  key: "/company",
                  icon: <TeamOutlined />,
                  label: "ข้อมูลบริษัท",
                  url: "/company",
                },
                {
                  key: "/user",
                  icon: <TeamOutlined />,
                  label: "ผู้ใช้งาน",
                  url: "/user",
                },
                // {
                //   key: "/user-role",
                //   icon: <ApartmentOutlined />,
                //   label: "ระดับผู้ใช้งาน",
                //   url: "/user-role",
                // },
              ],
            },
          ]}
        />
      </Sider>
      <Layout>
        <AppHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <AppContent />
        <AppFooter />
      </Layout>
    </Layout>
  );
};
export default DefaultLayout;
